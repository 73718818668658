import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TitleBar from "./components/TitleBar";
import ReplyBar from "./components/ReplyBar";
import InputBar from "./components/InputBar";
import { fetchAiResponse } from "./api/AiService";
import "./App.css";

function App() {
  const [messages, setMessages] = useState([{ text: "Hello, you can ask me about anything.", sender: "ai" }]);

  // 每次 messages 更新后，滚动到底部
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 100);
  };

  const handleSend = async (userInput) => {
    // Adding user's message to the conversation
    setMessages((messages) => [...messages, { text: userInput, sender: "user" }]);

    // Fetching AI response
    try {
      const response = await fetchAiResponse(userInput);
      // Ensure response is in English or handle the response accordingly
      setMessages((messages) => [...messages, { text: response.response, sender: "ai" }]);
    } catch (error) {
      console.error("Error fetching AI response:", error);
    }
  };

  return (
    <div className="App">
      <div className="container">
        <TitleBar />
        <div className="row">
          <ReplyBar messages={messages} />
        </div>
        <div className="row">
          <InputBar onSend={handleSend} />
        </div>
      </div>
    </div>
  );
}

export default App;
