export const fetchAiResponse = async (prompt) => {
  try {
    const response = await fetch("https://news-ai-api.trustavo.com/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ prompt }),
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Network response was not ok.");
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};
