import React from 'react';
import './TitleBar.css';
import logo from './trustavo-logo.jpg'; // Import the logo here

const TitleBar = () => (
    <div className="title-bar">
        <img src={logo} alt="Logo" className="logo"/> {/* Use the imported logo */}
        <h1 className="title-text">Trustavo AI</h1>
    </div>
);

export default TitleBar;
