import React, { useState } from "react";
import "./InputBar.css";

const InputBar = ({ onSend }) => {
  const [input, setInput] = useState("");

  const handleSend = () => {
    onSend(input);
    setInput("");
  };

  return (
    <div className="input-bar">
      <textarea value={input} onChange={(e) => setInput(e.target.value)} placeholder="Type your message here..." />
      <button onClick={handleSend}>Send</button>
    </div>
  );
};

export default InputBar;
