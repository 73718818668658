// ReplyBar.js
import React from 'react';
import './ReplyBar.css';

const formatMessage = (text) => {
  // Split the text by newline characters and return an array of JSX elements
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

const ReplyBar = ({ messages }) => (
    <div className="reply-bar">
        {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
                <strong>{msg.sender === 'user' ? 'You: ' : 'AI: '}</strong>
                {formatMessage(msg.text)}
            </div>
        ))}
    </div>
);

export default ReplyBar;
